import { fetchJSON } from '../utils/fetchJSON';
import { getToken } from '../utils/cookieToken';

export const getUserAccountService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/account', parameters);
};

export const updateUserAccountService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload)
	};

	return fetchJSON('/api/account', parameters);
};

export const getEmployeeCountService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/directory/employee-count', parameters);
};

export const getKindActivityService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/directory/kind-activity', parameters);
};

export const createLicense = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/license/generate', parameters);
};

export const getKeyPerson = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/license/private', parameters);
};

export const getAccountLicenseCount = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/license/license-count', parameters);
};
