import { fromJS } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	RESET_USER,
	GET_USER_ACCOUNT,
	UPDATE_USER_ACCOUNT,
	GET_EMPLOYEE_COUNT,
	GET_KIND_ACTIVITY,
	RESET_ERROR,
	CREATE_LICENSE,
	GET_KEY_PERSON,
	GET_ACCOUNT_LICENSE_COUNT
} from '../../store/constants';

export const resetError = type => ({
	type: type + RESET_ERROR
});

export const getUserAccountAction = () => ({
	type: GET_USER_ACCOUNT,
});

export const updateUserAccountAction = user => ({
	type: UPDATE_USER_ACCOUNT,
	payload: user
});

export const getInformationAction = () => ({
	type: GET_EMPLOYEE_COUNT,
});

export const createLicense = () => ({
	type: CREATE_LICENSE,
});

export const getKeyPerson = () => ({
	type: GET_KEY_PERSON
});

export const getAccountLicenseCount = () => ({
	type: GET_ACCOUNT_LICENSE_COUNT
});

const initialState = fromJS({
	isFetching: {},
	error: {},
	user: null,
	keyPerson: null,
	info: {
		employee: null,
		activities: null
	},
	licenseCount: {}
});

const actionHandlers = {
	[GET_USER_ACCOUNT + REQUEST]: state => state
		.setIn(['isFetching', 'user'], true)
		.deleteIn(['error', 'user']),
	[GET_USER_ACCOUNT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'user'], false)
			.set('user', fromJS(payload));
	},
	[GET_USER_ACCOUNT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'user'], false)
			.setIn(['error', 'user'], fromJS(payload));
	},
	[UPDATE_USER_ACCOUNT + REQUEST]: state => state
		.setIn(['isFetching', 'userUpd'], true)
		.deleteIn(['error', 'userUpd']),
	[UPDATE_USER_ACCOUNT + SUCCESS]: state => state
		.setIn(['isFetching', 'userUpd'], false),
	[UPDATE_USER_ACCOUNT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'userUpd'], false)
			.setIn(['error', 'userUpd'], fromJS(payload));
	},
	[GET_EMPLOYEE_COUNT + REQUEST]: state => state
		.setIn(['isFetching', 'info'], true)
		.deleteIn(['error', 'info']),
	[GET_EMPLOYEE_COUNT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'info'], false)
			.setIn(['info', 'employee'], payload);
	},
	[GET_EMPLOYEE_COUNT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'info'], false)
			.setIn(['error', 'info'], fromJS(payload));
	},
	[GET_KIND_ACTIVITY + REQUEST]: state => state
		.setIn(['isFetching', 'info'], true)
		.deleteIn(['error', 'info']),
	[GET_KIND_ACTIVITY + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'info'], false)
			.setIn(['info', 'activities'], payload);
	},
	[GET_KIND_ACTIVITY + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'info'], false)
			.setIn(['error', 'info'], fromJS(payload));
	},
	[RESET_USER]: state => state
		.set('isFetching', fromJS({}))
		.set('error', fromJS({}))
		.set('user', null)
		.set('keyPerson', null),
	[GET_KEY_PERSON + REQUEST]: state => state
		.setIn(['isFetching', 'keyPerson'], true)
		.deleteIn(['error', 'keyPerson']),
	[GET_KEY_PERSON + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'keyPerson'], false)
			.set('keyPerson', fromJS(payload));
	},
	[GET_KEY_PERSON + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'keyPerson'], false)
			.setIn(['error', 'keyPerson'], fromJS(payload));
	},
	[GET_ACCOUNT_LICENSE_COUNT + REQUEST]: state => state
		.setIn(['isFetching', 'licenseCount'], true)
		.deleteIn(['error', 'licenseCount']),
	[GET_ACCOUNT_LICENSE_COUNT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseCount'], false)
			.set('licenseCount', fromJS(payload));
	},
	[GET_ACCOUNT_LICENSE_COUNT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseCount'], false)
			.setIn(['error', 'licenseCount'], fromJS(payload));
	},
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

const actionCreators = {
	getUserAccountAction,
	updateUserAccountAction,
	resetError,
	createLicense,
	getKeyPerson,
	getAccountLicenseCount,
	actionHandlers,
};

export { actionCreators };

export default reducer;
