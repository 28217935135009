import { fromJS } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	RESET_ERROR,
	REGISTER_USER,
	LOGIN_USER,
	LOGOUT_USER,
	CONFIRM_TOKEN,
	FORGOT_PASSWORD,
	SET_NEW_PASSWORD,
	CHANGE_USER_PASSWORD,
	PUBLIC_MODAL_AUTH_REGISTER,
	PUBLIC_MODAL_AUTH_REGISTER_SUCCESS,
	PUBLIC_MODAL_AUTH_FORGOT,
	PUBLIC_MODAL_AUTH_FORGOT_STEP_TWO,
	PUBLIC_MODAL_PASSWORD_RESET,
	PUBLIC_MODAL_PASSWORD_RESET_STEP_TWO,
} from '../../store/constants';
import { isLoggedIn } from '../../utils/cookieToken';

export const resetError = type => ({
	type: type + RESET_ERROR
});

export const registerUserAction = user => ({
	type: REGISTER_USER,
	payload: user
});

export const loginUserAction = user => ({
	type: LOGIN_USER,
	payload: user
});

export const logoutUserAction = () => ({
	type: LOGOUT_USER,
});

export const confirmTokenAction = token => ({
	type: CONFIRM_TOKEN,
	payload: token
});

export const forgotPassword = password => ({
	type: FORGOT_PASSWORD,
	payload: password
});

export const confirmResetPassword = token => ({
	type: CONFIRM_TOKEN,
	payload: token
});

export const setNewPassword = data => ({
	type: SET_NEW_PASSWORD,
	payload: data
});

export const changeUserPasswordAction = data => ({
	type: CHANGE_USER_PASSWORD,
	payload: data
});

const initialState = fromJS({
	isFetching: {},
	error: {},
	registrationStep: PUBLIC_MODAL_AUTH_REGISTER,
	forgotStep: PUBLIC_MODAL_AUTH_FORGOT,
	newPasswordStep: PUBLIC_MODAL_PASSWORD_RESET,
	isLoggedIn: isLoggedIn(),
});

const actionHandlers = {
	[REGISTER_USER + REQUEST]: state => state
		.setIn(['isFetching', 'register'], true)
		.deleteIn(['error', 'register']),
	[REGISTER_USER + SUCCESS]: state => state
		.setIn(['isFetching', 'register'], false)
		.set('registrationStep', PUBLIC_MODAL_AUTH_REGISTER_SUCCESS),
	[REGISTER_USER + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'register'], false)
			.setIn(['error', 'register'], fromJS(payload));
	},
	[REGISTER_USER + RESET_ERROR]: state => state
		.set('registrationStep', PUBLIC_MODAL_AUTH_REGISTER)
		.deleteIn(['error', 'register']),
	[LOGIN_USER + REQUEST]: state => state
		.setIn(['isFetching', 'login'], true)
		.deleteIn(['error', 'login']),
	[LOGIN_USER + SUCCESS]: state => state
		.setIn(['isFetching', 'login'], false)
		.deleteIn(['error', 'login'])
		.set('isLoggedIn', true),
	[LOGIN_USER + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'login'], false)
			.setIn(['error', 'login'], fromJS(payload));
	},
	[LOGIN_USER + RESET_ERROR]: state => state
		.deleteIn(['error', 'login']),
	[LOGOUT_USER + SUCCESS]: state => state.set('isLoggedIn', false),
	[CONFIRM_TOKEN + REQUEST]: state => state
		.setIn(['isFetching', 'confirmToken'], true),
	[CONFIRM_TOKEN + SUCCESS]: state => state
		.setIn(['isFetching', 'confirmToken'], false),
	[CONFIRM_TOKEN + ERROR]: (state, action) => {
		const { payload } = action;
		return state.setIn(['error', 'confirmToken'], fromJS(payload));
	},
	[CONFIRM_TOKEN + RESET_ERROR]: state => state
		.deleteIn(['error', 'confirmToken']),
	[SET_NEW_PASSWORD + REQUEST]: state => state
		.setIn(['isFetching', 'newPassword'], true)
		.deleteIn(['error', 'newPassword']),
	[SET_NEW_PASSWORD + SUCCESS]: state => state
		.set('newPasswordStep', PUBLIC_MODAL_PASSWORD_RESET_STEP_TWO)
		.setIn(['isFetching', 'newPassword'], false),
	[SET_NEW_PASSWORD + ERROR]: (state, action) => {
		const { payload } = action;
		return state.setIn(['error', 'newPassword'], fromJS(payload));
	},
	[SET_NEW_PASSWORD + RESET_ERROR]: state => state
		.set('newPasswordStep', PUBLIC_MODAL_PASSWORD_RESET)
		.deleteIn(['error', 'newPassword']),
	[FORGOT_PASSWORD + REQUEST]: state => state
		.setIn(['isFetching', 'forgotPassword'], true)
		.deleteIn(['error', 'forgotPassword']),
	[FORGOT_PASSWORD + SUCCESS]: state => state
		.setIn(['isFetching', 'forgotPassword'], false)
		.set('forgotStep', PUBLIC_MODAL_AUTH_FORGOT_STEP_TWO),
	[FORGOT_PASSWORD + ERROR]: (state, action) => {
		const { payload } = action;
		return state.setIn(['error', 'forgotPassword'], fromJS(payload));
	},
	[FORGOT_PASSWORD + RESET_ERROR]: state => state
		.set('forgotStep', PUBLIC_MODAL_AUTH_FORGOT)
		.deleteIn(['error', 'forgotPassword']),
	[CHANGE_USER_PASSWORD + REQUEST]: state => state
		.setIn(['isFetching', 'changePassword'], true)
		.deleteIn(['error', 'changePassword']),
	[CHANGE_USER_PASSWORD + SUCCESS]: state => state
		.setIn(['isFetching', 'changePassword'], false),
	[CHANGE_USER_PASSWORD + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'changePassword'], false)
			.setIn(['error', 'changePassword'], fromJS(payload));
	},
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

const actionCreators = {
	registerUserAction,
	loginUserAction,
	logoutUserAction,
	confirmTokenAction,
	forgotPassword,
	confirmResetPassword,
	setNewPassword,
	actionHandlers
};

export { actionCreators };

export default reducer;
