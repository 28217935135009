import React, { lazy, Suspense } from 'react';
import ModalSuccess from '../../components/ModalSuccess/ModalSuccess';
import EmployeeTokenModal from '../../components/EmployeeTokenModal';

const Auth = lazy(() => import('./layout/Auth'));

const CoreLayout = () => (
	<Suspense fallback={<div>Loading...</div>}>
		<EmployeeTokenModal />
		<Auth />
		<ModalSuccess />
	</Suspense>
);

export default CoreLayout;
