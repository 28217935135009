import React from 'react';
import { func, string } from 'prop-types';
import './SuccessInfo.sass';
import info from '../../static/i/info.svg';

const SuccessInfo = ({ text, handleClose, goBack }) => (
	<div className='success-info__success'>
		<img src={info} alt='' />
		<div className='success-info__success-text'>
			{ text }
		</div>
		<div className='success-info__success-button'>
			<button
				type='button'
				className='btn btn-default btn-w100'
				onClick={handleClose}
			>
				Закрыть
			</button>
		</div>
		{ goBack && (
			<div className='success-info__success-button'>
				<button
					type='button'
					className='btn btn-thin-primary btn-w100'
					onClick={goBack}
				>
				Добавить еще
				</button>
			</div>
		) }
	</div>
);

SuccessInfo.propTypes = {
	text: string.isRequired,
	handleClose: func.isRequired,
	goBack: func,
};

SuccessInfo.defaultProps = {
	goBack: null
};

export default SuccessInfo;
