import { put, call } from 'redux-saga/effects';
import {
	registerUserService,
	loginUserService,
	confirmTokenService,
	forgotPasswordService,
	setNewPasswordService,
	changeUserPasswordService,
} from '../services/registrationService';
import history from '../store/history';
import { setToken, removeToken } from '../utils/cookieToken';
import { successCodes } from '../static/errors';

import * as types from '../store/constants';

export function* registerSaga(payload) {
	yield put({ type: `${types.REGISTER_USER + types.REQUEST}` });
	try {
		const response = yield call(registerUserService, payload);
		yield put({ type: `${types.REGISTER_USER + types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.REGISTER_USER + types.ERROR}`, payload: error });
	}
}

export function* loginSaga(payload) {
	yield put({ type: `${types.LOGIN_USER + types.REQUEST}` });
	try {
		const response = yield call(loginUserService, payload);
		const { header_value } = response;
		yield put({ type: `${types.LOGIN_USER + types.SUCCESS}` });
		yield call(setToken, header_value);
		yield call(history.push, '/account');
	} catch (error) {
		yield put({ type: `${types.LOGIN_USER + types.ERROR}`, payload: error });
	}
}

export function* logoutSaga() {
	yield put({ type: `${types.LOGOUT_USER + types.SUCCESS}` });
	yield call(removeToken);
	yield put({ type: types.RESET_USER });
	yield put({ type: types.RESET_STRUCTURE });
}

export function* confirmTokenSaga(payload) {
	yield put({ type: `${types.CONFIRM_TOKEN}${types.REQUEST}` });
	try {
		const response = yield call(confirmTokenService, payload);
		yield put({ type: `${types.CONFIRM_TOKEN}${types.SUCCESS}`, payload: response });
		yield call(history.push, '/');
	} catch (error) {
		yield put({ type: `${types.CONFIRM_TOKEN}${types.ERROR}`, payload: error });
	}
}

export function* forgotPassword(payload) {
	yield put({ type: `${types.FORGOT_PASSWORD}${types.REQUEST}` });
	try {
		const response = yield call(forgotPasswordService, payload);
		yield put({ type: `${types.FORGOT_PASSWORD}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.FORGOT_PASSWORD}${types.ERROR}`, payload: error });
	}
}

export function* setNewPassword(payload) {
	yield put({ type: `${types.SET_NEW_PASSWORD}${types.REQUEST}` });
	try {
		const response = yield call(setNewPasswordService, payload);
		yield put({ type: `${types.SET_NEW_PASSWORD}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.SET_NEW_PASSWORD}${types.ERROR}`, payload: error });
	}
}

export function* changeUserPasswordSaga(payload) {
	yield put({ type: `${types.CHANGE_USER_PASSWORD}${types.REQUEST}` });
	try {
		yield call(changeUserPasswordService, payload);
		yield put({ type: `${types.CHANGE_USER_PASSWORD}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_SWITCH,
			payload: {
				oldModalName: types.ACCOUNT_MODAL_PASSWORD_CHANGE,
				newModalName: types.MODAL_SUCCESS,
				newModalData: { message: successCodes.changeUserPassword.message }
			}
		});
	} catch (error) {
		yield put({ type: `${types.CHANGE_USER_PASSWORD}${types.ERROR}`, payload: error });
	}
}
