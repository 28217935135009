import { put, call } from 'redux-saga/effects';
import {
	getUserAccountService,
	updateUserAccountService,
	getEmployeeCountService,
	getKindActivityService,
	getAccountLicenseCount,
	createLicense,
	getKeyPerson
} from '../services/userService';
import { successCodes } from '../static/errors';

import * as types from '../store/constants';

export function* getKeyPersonSaga() {
	yield put({ type: `${types.GET_KEY_PERSON}${types.REQUEST}` });
	try {
		const response = yield call(getKeyPerson);
		yield put({ type: `${types.GET_KEY_PERSON}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_KEY_PERSON}${types.ERROR}`, payload: error });
	}
}

export function* getAccountLicenseCountSaga() {
	yield put({ type: `${types.GET_ACCOUNT_LICENSE_COUNT}${types.REQUEST}` });
	try {
		const response = yield call(getAccountLicenseCount);
		yield put({ type: `${types.GET_ACCOUNT_LICENSE_COUNT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_ACCOUNT_LICENSE_COUNT}${types.ERROR}`, payload: error });
	}
}

export function* userAccountSaga() {
	yield getKeyPersonSaga();
	yield getAccountLicenseCountSaga();
	yield put({ type: `${types.GET_USER_ACCOUNT}${types.REQUEST}` });
	try {
		const response = yield call(getUserAccountService);
		yield put({ type: `${types.GET_USER_ACCOUNT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_USER_ACCOUNT}${types.ERROR}`, payload: error });
	}
}

export function* updateUserAccountSaga(payload) {
	yield put({ type: `${types.UPDATE_USER_ACCOUNT}${types.REQUEST}` });
	try {
		yield call(updateUserAccountService, payload);
		yield put({ type: `${types.UPDATE_USER_ACCOUNT}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_SUCCESS,
				modalData: { message: successCodes.updateUserAccount.message }
			}
		});
		const response = yield call(getUserAccountService);
		yield put({ type: `${types.GET_USER_ACCOUNT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.UPDATE_USER_ACCOUNT}${types.ERROR}`, payload: error });
	}
}

export function* getInformationSaga() {
	yield put({ type: `${types.GET_EMPLOYEE_COUNT}${types.REQUEST}` });
	yield put({ type: `${types.GET_KIND_ACTIVITY}${types.REQUEST}` });
	try {
		const responseEmployee = yield call(getEmployeeCountService);
		const responseActivities = yield call(getKindActivityService);
		yield put({ type: `${types.GET_EMPLOYEE_COUNT}${types.SUCCESS}`, payload: responseEmployee });
		yield put({ type: `${types.GET_KIND_ACTIVITY}${types.SUCCESS}`, payload: responseActivities });
	} catch (error) {
		yield put({ type: `${types.GET_EMPLOYEE_COUNT}${types.ERROR}`, payload: error });
		yield put({ type: `${types.GET_KIND_ACTIVITY}${types.ERROR}`, payload: error });
	}
}

export function* createLicenseSaga() {
	yield put({ type: `${types.CREATE_LICENSE}${types.REQUEST}` });
	try {
		const response = yield call(createLicense);
		yield put({ type: `${types.CREATE_LICENSE}${types.SUCCESS}`, payload: response });
		yield userAccountSaga();
	} catch (error) {
		yield put({ type: `${types.CREATE_LICENSE}${types.ERROR}`, payload: error });
	}
}
