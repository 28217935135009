import {
	RUB,
	DOLLAR,
	EURO,
	UAH
} from '../store/constants';

export const accountType = {
	PRIVATE_PERSON: 'PRIVATE_PERSON',
	ORGANIZATION: 'ORGANIZATION',
};

export const genderTypes = {
	MALE: 'MALE',
	FEMALE: 'FEMALE',
};

export const genderTypesTest = [
	{
		gender: 'MALE',
		title: 'Муж'
	},
	{
		gender: 'FEMALE',
		title: 'Жен'
	},
];

export const successCodesEmployer = {
	message: 'Данные успешно обновлены',
	translation_key: 'error.code.group.200'
};

export const errorCodesEmployer = {
	404: {
		message: 'Employee not found',
		translation_key: 'error.code.employer.404'
	},
};

export const errorCodesEmployerDetail = {
	400: {
		message: 'Please enter the correct field',
		translation_key: 'error.code.employer.detail.400'
	},
};

export const errorCodesPaymentSystem = {
	409: {
		message: 'Account already have active license'
	},
	404: {
		message: 'Personality test not found'
	},
	401: {
		message: 'Authorization error'
	},
	422: {
		message: 'License count not be null or <= 0'
	}
};

export const errorCodesCompletePaypal = {
	409: {
		message: 'Платеж был отклонен, попробуйте заново. Узнайте причину ошибки платежа в вашем банке (при оплате банковской картой) или через PayPal (при оплате с баланса PayPal).'
	},
	401: {
		message: 'Authorization error'
	},
	404: {
		message: 'License not found'
	}
};

export const errorCodesCancelPaypal = {
	409: {
		message: 'Order already accepted'
	},
	401: {
		message: 'Authorization error'
	}
};

export const employerInfo = {
	DATE: 'date',
	ID: 'id',
	EMAIL: 'email',
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	GENDER: 'gender',
	ORGANIZATION_GROUP_VIEW: 'organizationGroupView',
	REPORT_STATE: 'employeeReportState',
	BIRTHDAY: 'birthday',
	TEST_VIEW: 'test_view',
	GROUP_NAME: 'groupName'
};

export const employerStatus = {
	AWAITING: 'Ожидание подтверждения'
};

export const structureGroupsView = {
	VIEW_TABLE: 'Таблица',
	VIEW_SCHEMA: 'Структура'
};

export const employeesView = {
	VIEW_ALL_EMPLOYEES: 'Мои пользователи',
	VIEW_SHARED_EMPLOYEES: 'Внешние пользователи'
};

export const authFields = {
	EMAIL: 'email',
	PASSWORD: 'password',
	AGREE_TERMS: 'agreeTerms'
};

export const userFields = {
	EMAIL: 'email',
	EMPLOYEE_COUNT: 'employee_count',
	FIRST_NAME: 'first_name',
	GENDER: 'gender',
	KIND_ACTIVITY: 'kind_activity',
	LANGUAGE: 'language',
	LAST_NAME: 'last_name',
	LICENSE_COUNT: 'license_count',
	ORGANIZATION_NAME: 'organization_name',
	TYPE: 'type'
};

export const reportFields = {
	REPORT_VIEW: 'reportView',
	COMPETENCIES: 'competency_view_list',
	COMP_DESCRIPTION_1: 'description_first',
	COMP_DESCRIPTION_2: 'description_second',
	COMP_NAME: 'name',
	COMP_VALUE: 'value',
	SKILLS: 'skillViewList',
	SKILLS_DESCRIPTION: 'description',
	SKILLS_NAME: 'name',
	SKILLS_BEST_RESULT: 'outstanding_result',
	SKILLS_VALUE: 'value',
	FIRST_NAME: 'first_name',
	LAST_NAME: 'last_name',
	TIME: 'time'
};

export const colors = {
	GOOD: {
		color: '#ff9900',
		opacityColor: 'rgba(255, 153, 0, 0.2)'
	},
	EXCELLENT: {
		color: '#3d85c6',
		opacityColor: 'rgba(61, 133, 198, 0.2)'
	},
	OUTSTANDING: {
		color: '#559610',
		opacityColor: 'rgba(85, 150, 16, 0.2)'
	},
	ACCEPTABLE: {
		color: '#ff0000',
		opacityColor: 'rgba(255, 0, 0, 0.2)'
	},
	OTHER: {
		color: '#F05656',
		opacityColor: 'rgba(13, 162, 160, 0.2)'
	}
};

export const reportLevels = {
	GOOD: {
		className: 'gamma',
		name: 'Хороший',
		color: colors.GOOD.color
	},
	EXCELLENT: {
		className: 'beta',
		name: 'Отличный',
		color: colors.EXCELLENT.color
	},
	OUTSTANDING: {
		className: 'alpha',
		name: 'Выдающийся',
		color: colors.OUTSTANDING.color
	},
	ACCEPTABLE: {
		className: 'psi',
		name: 'Приемлемый',
		color: colors.ACCEPTABLE.color
	},
	OTHER: {
		className: 'omega',
		name: 'Неприемлемый',
		color: colors.OTHER.color
	}
};

export const reportResultLevelSkill = {
	GOOD: {
		className: 'gamma',
		name: 'Хороший',
		color: colors.GOOD.color
	},
	EXCELLENT: {
		className: 'beta',
		name: 'Отличный',
		color: colors.EXCELLENT.color
	},
	OUTSTANDING: {
		className: 'alpha',
		name: 'Выдающийся',
		color: colors.OUTSTANDING.color
	},
	ACCEPTABLE: {
		className: 'psi',
		name: 'Приемлемый',
		color: colors.ACCEPTABLE.color
	},
	OTHER: {
		className: 'omega',
		name: 'Неприемлемый',
		color: colors.OTHER.color
	}
};

export const columnsReports = [
	{
		key: 'competency',
		name: 'Компетенция'
	},
	{
		key: 'result',
		name: 'Результат'
	},
	{
		key: 'value',
		name: 'Баллы'
	}
];

export const licenseKeyStatus = {
	CREATED: 'создан',
	SENT_SUCCESSFULLY: 'отправлен',
	SENT_FAILED: 'провалено',
	ACCEPT: 'принят',
	PASSED: 'пройден'
};

export const currencyList = [
	{
		name: 'USD',
		priceType: 'priceDollar',
		moneyType: DOLLAR,
		id: 1,
	},
	{
		name: 'EUR',
		priceType: 'priceEuro',
		moneyType: EURO,
		id: 2,
	},
	{
		name: 'RUB',
		priceType: 'priceRub',
		moneyType: RUB,
		id: 3,
	},
	{
		name: 'UAH',
		priceType: 'priceUah',
		moneyType: UAH,
		id: 4,
	}
];

export const paymentMethods = {
	PAY_PAL: 'PayPal',
	STRIPE: 'Stripe',
	ADMIN: 'админ'
};

export const transactionsStatus = {
	CREATE: 'создан',
	ACCEPTED: 'оплачен',
	FAILED: 'отклонен'
};

export const currencyDefault = currencyList[0];

export const titlePayModal = {
	default: 'Укажите кол-во сотрудников для которых хотите оплатить ключи на тестирование',
	paytest: 'Ключ/и для доступа к тесту будут отправлены на вашу почту, после успешной оплаты'
};
