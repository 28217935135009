/* eslint no-param-reassign: ["error", { "props": false }] */

import { API_ROOT } from '../store/constants';

export const fetchFile = (url, options = {}) => (
	fetch(`${API_ROOT}${url}`, options)
		.then(response => (
			response.ok ? response.blob() : Promise.reject({ status: response.status })
		)).catch(error => Promise.reject(error))
);

export const fetchJSON = (url, options = {}, root) => (
	fetch(`${root || API_ROOT}${url}`, options)
		.then(response => (
			response.ok ? response.text() : Promise.reject({
				status: response.status, data: response.text()
			})
		))
		.then((text) => {
			try {
				// Do your JSON handling here
				return JSON.parse(text);
			} catch (err) {
				// It is text, do you text handling here
				return text;
			}
		})
		.catch(error => Promise.reject(error))
);
