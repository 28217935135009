const { NODE_ENV, REACT_APP_API_TYPE } = process.env;

const ENDPOINTS = {
	development: {
		ROOT: {
			path: 'http://localhost:3000',
		},
		API_ROOT: {
			path: 'https://dev-backend.gntpower.com',
		},
		API_SERVICE_ROOT: {
			path: 'https://gnt-service-pdf.app.se7ensky.com',
		}
		//	http://localhost:3001'
	},
	production: {
		ROOT: {
			path: 'https://client.gntpower.com',
		},
		API_ROOT: {
			path: 'https://backend.gntpower.com',
		},
		API_SERVICE_ROOT: {
			path: 'https://gnt-service-pdf.app.se7ensky.com',
		}
	}
};

module.exports = ENDPOINTS[REACT_APP_API_TYPE || NODE_ENV];
